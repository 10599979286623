import React from "react";
import "../../styles/Frontpage.css";

import jaulilogo from "../../assets/icons/jauli-logo.png"

const Navbar = () => {
  return (
    <>
      {/* <div style={{height:"40px"}} className="tape">bridging the gap between african migrants and african cuisines in diaspora 😋</div> */}
      <div className="nav">
        <div className="nav-container">
          <a href="/" className="nav-logo">
            <img width="100" src={jaulilogo} alt="" />
          </a>
        </div>
      </div>
      {/* <HorizontalRule/> */}
    </>
  );
};

export default Navbar;