import { createContext , useState } from "react";

const NavContext = createContext({});

export const NavProvider = ({children}) => {
    const [isDocOpen, setIsDocOpen] = useState(false);
    const [isSideNav, setIsSideNav] = useState(false);

    return (
        <NavContext.Provider value={{isDocOpen , isSideNav , setIsDocOpen  , setIsSideNav}} >
            {children}
        </NavContext.Provider>
    )
 }

 export default NavContext