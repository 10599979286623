import React, {useState} from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const Button = ({ buttonText, icon, imageSrc, style, borderColor, backgroundColor, textColor, onClick, linkURL, dropdownItems }) => {
  const buttonStyle = {
    borderColor: borderColor || "black",
    backgroundColor: backgroundColor || "transparent",
    color: textColor || "black",
    borderRadius: style?.borderRadius || 50,
  };

  const isAvatarGroup = React.isValidElement(icon) && icon.type.displayName === "AvatarGroup";

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [selectedDropdownItem, setSelectedDropdownItem] = useState(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // const handleDropdownItemClick = (item) => {
  //   setSelectedDropdownItem(item);
  //   setIsDropdownOpen(false);
  // };
  
  return (
    <a rel="noreferrer" href={linkURL} style={{ textDecoration: "none" }}>
      <div className="button-container">
      <button onClick={() => { toggleDropdown(); onClick && onClick(); }} onClickxx={onClick} className="nav-button" style={buttonStyle}>
        {isAvatarGroup ? icon : (
          <>
            {imageSrc ? <img src={imageSrc} alt="Button Icon" className="nav-language-image" /> : null}
            {icon ? <>{icon} </> : null}
            {buttonText}
            {dropdownItems ? (isDropdownOpen ? <FaAngleUp /> : <FaAngleDown />) : null}
          </>
        )}
      </button>
      {dropdownItems && dropdownItems.length > 0 && isDropdownOpen && (
        <div className="dropdown">
          {dropdownItems.map((item, index) => (
            <button style={{color: "#151515"}} className="dropdown-item" key={index} onClick={item.onClick}>
              {item.image ? <img src={item.image} alt="" className="dropdown-item-image nav-language-image" /> : null}
              {item.label}
            </button>
          ))}
        </div>
      )}
      </div>
    </a>
  );
};

export default Button;
