import React from "react";
import Navbar from "../components/Frontpage/Navbar";
import Section from "../components/Frontpage/Section";
// import NavContext from "../context/NavProvider";
const Home = () => {
  // const { isDocOpen, isSideNav } = useContext(NavContext);

  return (
    <main className="front-page">
      <>
        <Navbar></Navbar>
        <Section />
        {/* <Footer /> */}
      </>
      {/* {isDocOpen && <DocModal />}
      {isSideNav && <SideNav />} */}
    </main>
  );
};

export default Home;